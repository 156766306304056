import React from "react";
import "./Register.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";

const usernameInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, type, ...others } =
    fieldRenderProps;
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <Input placeholder={label} type={type} />
    </FieldWrapper>
  );
};

const Register: React.FC = () => {
  const navigate = useNavigate();
  const login = () => {
    navigate("/");
  };
  return (
    <div className="container-fluid login-container">
      <div className="row h-100">
        <div className="col-8 login-background"></div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <Form
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "80%" }}>
                <legend className="mb-5">Accept Invitation</legend>
                <Field
                  name={"username"}
                  label={"Username"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"First Name"}
                  label={"First Name"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"Last Name"}
                  label={"last Name"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"Email"}
                  label={"Email"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"Phone"}
                  label={"Phone"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"Role"}
                  label={"Role"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"Facility"}
                  label={"Facility"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"password"}
                  label={"Password"}
                  component={usernameInput}
                  type={"password"}
                />
                <Field
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  component={usernameInput}
                  type={"password"}
                />
                <div className="k-form-buttons mt-5">
                  <Button
                    themeColor={"primary"}
                    style={{ width: "100%" }}
                    onClick={login}
                  >
                    Sign Up
                  </Button>
                </div>
              </FormElement>
            )}
          ></Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
