import React from "react";

const Umm: React.FC = () => {
  return (
    <React.Fragment>
      <div className="alert alert-primary m-3" role="alert">
        UMM under development.
      </div>
    </React.Fragment>
  );
};

export default Umm;
