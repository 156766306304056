import React from "react";
import "./Login.scss";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useNavigate } from "react-router-dom";

const usernameInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, type, ...others } =
    fieldRenderProps;
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <Input placeholder={label} type={type} />
    </FieldWrapper>
  );
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const signIn = () => {
    // navigate("/app");
    navigate("/two-factor");
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="container-fluid login-container">
      <div className="row h-100">
        <div className="col-8 login-background"></div>
        <div className="col-4 d-flex align-items-center justify-content-center">
          <Form
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "80%" }}>
                <legend className="mb-5">Sign In</legend>
                <Field
                  name={"username"}
                  label={"Username"}
                  component={usernameInput}
                  type={"text"}
                />
                <Field
                  name={"password"}
                  label={"Password"}
                  component={usernameInput}
                  type={"password"}
                />
                <div className="k-form-buttons mt-5">
                  <Button
                    themeColor={"primary"}
                    style={{ width: "100%" }}
                    onClick={signIn}
                  >
                    Sign In
                  </Button>
                </div>
                <div className="resend-code-container mt-2">
                  <a
                    href="#"
                    onClick={forgotPassword}
                    className="resend-code-link"
                    style={{ textDecoration: "none" }}
                  >
                    Forgot Password?
                  </a>
                </div>
              </FormElement>
            )}
          ></Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
