import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import {
  menuIcon,
  userIcon,
  clipboardIcon,
  gearIcon,
  setColumnPositionIcon,
  layoutStackedIcon,
} from "@progress/kendo-svg-icons";
import {
  Drawer,
  DrawerContent,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Outlet, useNavigate } from "react-router-dom";
import "./Layout.scss";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const items = [
  {
    text: "Dashboard",
    svgIcon: layoutStackedIcon,
    selected: true,
    route: "/app",
  },
  { text: "Users", svgIcon: userIcon, route: "/app/users" },
  { text: "Reports", svgIcon: clipboardIcon, route: "/app/reports" },
  { text: "UMM", svgIcon: setColumnPositionIcon, route: "/app/umm" },
  { text: "Settings", svgIcon: gearIcon, route: "/app/settings" },
];

const DrawerRouterContainer: React.FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(true);
  const [selected, setSelected] = React.useState(
    items.findIndex((x) => x.selected === true)
  );

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const onSelect = (e: DrawerSelectEvent) => {
    navigate(e.itemTarget.props.route);
    setSelected(e.itemIndex);
  };

  return (
    <div>
      <div className="custom-toolbar d-flex align-items-center justify-content-between p-2 ">
        <div className="d-flex align-items-center">
          <Button svgIcon={menuIcon} fillMode="flat" onClick={handleClick} />
          <span className="mail-box ms-3 me-5">Sensorsuite</span>
          <div className="ms-5">
            <DropDownList
              size={"small"}
              fillMode={"flat"}
              rounded={"medium"}
              data={["Abrazo Scottsdale Campus"]}
              defaultItem={"Atlas Medical Center"}
              style={{
                width: "300px",
              }}
            />
          </div>
        </div>

        <div className="d-flex align-items-center me-2">
          {/* <Button className="me-2" icon="bell" /> */}
          <Button themeColor={"primary"} size={"small"}>
            JS
          </Button>
        </div>
      </div>
      <Drawer
        expanded={expanded}
        position={"start"}
        mode={"push"}
        mini={true}
        items={items.map((item, index) => ({
          ...item,
          selected: index === selected,
        }))}
        onSelect={onSelect}
        style={{ height: "100vh" }}
      >
        <DrawerContent className="content vh-100">
          <Outlet />
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default DrawerRouterContainer;
