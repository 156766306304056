import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Page404 from "./pages/error/Page404";
import "bootstrap/dist/css/bootstrap.min.css";
import Users from "./pages/users/Users";
import Reports from "./pages/reports/Reports";
import Umm from "./pages/umm/Umm";
import Layout from "./components/layout/Layout";
import Settings from "./pages/settings/Settings";
import Register from "./pages/register/Register";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import TwoFactor from "./pages/two-factor/TwoFactor";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Login />}></Route>
        <Route index path="/register" element={<Register />}></Route>
        <Route index path="/two-factor" element={<TwoFactor />}></Route>
        <Route
          index
          path="/forgot-password"
          element={<ForgotPassword />}
        ></Route>
        <Route path="/app" element={<Layout />}>
          <Route index element={<Dashboard />}></Route>
          <Route path="users" element={<Users />}></Route>
          <Route path="reports" element={<Reports />}></Route>
          <Route path="umm" element={<Umm />}></Route>
          <Route path="settings" element={<Settings />}></Route>
        </Route>
        <Route path="*" element={<Page404 />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
