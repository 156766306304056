import React from "react";
import "./Reports.scss";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

const Reports: React.FC = () => {
  return (
    <div className="mt-2">
      <h4 className="ms-3 text-secondary">Reports</h4>
      <div className="bg-white reports-container m-3 p-3">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label>Date</label>
              <DatePicker defaultValue={new Date()} />
            </div>
            <div className="mb-3">
              <label>Unit</label>
              <DropDownList
                data={["5-South", "6-South", "7-South", "8-South"]}
                defaultValue="5-South"
                fillMode={"outline"}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label>Report Type</label>
              <DropDownList
                data={["Patient Discharge", "Patient Daily", "Unit Daily"]}
                defaultValue="Patient Discharge"
                fillMode={"outline"}
              />
            </div>
            <div className="mb-3">
              <label>Room/Bed</label>
              <DropDownList
                data={["Room-2A", "Room-2B", "Room-2C"]}
                defaultValue="Room-2A"
                fillMode={"outline"}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button themeColor={"primary"} className="me-2">
            Generate
          </Button>
          <Button className="ml-2">Clear</Button>
        </div>
      </div>
    </div>
  );
};

export default Reports;
