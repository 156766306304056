import React from "react";

const Settings: React.FC = () => {
  return (
    <React.Fragment>
      <div>
        <div className="alert alert-primary m-3" role="alert">
          Settings under development.
        </div>
      </div>
    </React.Fragment>
  );
};

export default Settings;
